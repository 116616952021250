<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Formações</h1>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-col-12 p-md-2 p-d-inline-flex p-ac-center">
        <label for="year-selector">Ano</label>
        <InputNumber
          name="year"
          :value="year"
          class="p-col-2"
          showButtons
          buttonLayout="horizontal"
          incrementButtonIcon="pi pi-plus"
          decrementButtonIcon="pi pi-minus"
          :step="1"
          :min="currentYear - 10"
          :max="currentYear"
          :useGrouping="false"
          @input="yearChanged"
        />
      </div>
      <div class="p-col-12 p-md-2 p-ml-3">
        <Button
          label="Adicionar Formação"
          icon="pi pi-plus"
          class="p-button-success"
          @click="newTraining"
        ></Button>
      </div>
      <div class="p-col-12 p-md-2">
        <Button
          v-if="filtered == false"
          label="Por Avaliar"
          @click="toEvaluated()"
        ></Button>
        <Button v-else label="Todas" @click="getTrainings()"></Button>
      </div>
    </div>

    <DataTable
      :value="trainingList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} pats"
      sortField="name"
      :sortOrder="1"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column field="name" header="Nome" sortable filterField="name">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="year" header="Ano" sortable filterField="year">
        <template #body="slotProps">
          {{ slotProps.data.year }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="duration" header="Duração" sortable filterField="duration">
        <template #body="slotProps">
          {{ slotProps.data.duration }} Horas
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="employeesCount"
        header="Formandos"
        sortable
        filterField="employees"
      >
        <template #body="slotProps">
          {{ slotProps.data.employeesCount }}
        </template>
      </Column>
      <Column field="evaluationCount" header="Avaliações" sortable>
        <template #body="slotProps">
          {{
            slotProps.data.evaluationCount > slotProps.data.employeesCount
              ? slotProps.data.employeesCount
              : slotProps.data.evaluationCount
          }}
        </template>
      </Column>
      <!-- <Column
        field="creator_username"
        header="Criada por"
        sortable
        filterField="creator_username"
      >
        <template #body="slotProps">
          {{ slotProps.data.creator_username }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column> -->
      <Column
        field="responsible_username"
        header="Responsável"
        sortable
        filterField="responsible_username"
      >
        <template #body="slotProps">
          {{ slotProps.data.responsible_username }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="active" header="Activa" sortable filterField="active">
        <template #body="slotProps">
          {{ slotProps.data.active == true ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' },
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-if="
              loggedUer.isEsa ||
              loggedUer.id == slotProps.data.creator_id ||
              loggedUer.id == slotProps.data.responsible_id ||
              loggedUer.department == 'Administradores'
            "
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-button-outlined p-mr-2"
            @click="edit(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      :visible.sync="showForm"
      :style="{ width: $isMobile() ? '100vw' : '80vw', position: 'relative' }"
      :header="
        training.id == undefined ? 'Criar Formação' : 'Atualizar Formação'
      "
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <form
        name="trainingForm"
        class="p-col-12"
        @submit.prevent="saveTraining"
        style="min-height: 50vh"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-3 p-mt-4">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="training.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('name') },
                  'form-control',
                ]"
                :name="'name'"
              />
              <label for="name">Nome</label>
            </span>
            <small v-if="errors.has('name')" class="p-error" role="alert">
              Nome é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-2 p-mt-4">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="training.training_entity"
                v-bind:class="[
                  { 'p-invalid': errors.has('training_entity') },
                  'form-control',
                ]"
                :name="'training_entity'"
              />
              <label for="training_entity">Entidade Formadora</label>
            </span>
            <small
              v-if="errors.has('training_entity')"
              class="p-error"
              role="alert"
            >
              Entidade Formadora é obrigatório
            </small>
          </div>

          <div class="p-field p-col-6 p-md-1 p-mt-4">
            <span class="p-float-label">
              <InputNumber
                v-validate="'required'"
                v-model="training.cost"
                v-bind:class="[
                  { 'p-invalid': errors.has('cost') },
                  'form-control',
                ]"
                :name="'cost'"
                mode="currency"
                currency="EUR"
                locale="pt-PT"
              />
              <label for="cost">Custo</label>
            </span>
            <small v-if="errors.has('cost')" class="p-error" role="alert">
              Custo é obrigatório
            </small>
          </div>
          <div class="p-field p-col-6 p-md-1 p-mt-4">
            <span class="p-float-label">
              <InputNumber
                :name="'duration'"
                v-validate="'required'"
                v-model="training.duration"
                v-bind:class="[
                  { 'p-invalid': errors.has('duration') },
                  'form-control',
                ]"
                mode="decimal"
                locale="pt-PT"
              />
              <label for="duration">Duração (Horas)</label>
            </span>
            <small v-if="errors.has('duration')" class="p-error" role="alert">
              Duração é obrigatório
            </small>
          </div>

          <div class="p-field p-col-6 p-md-1 p-mt-4">
            <span class="p-float-label">
              <InputNumber
                :name="'year'"
                v-validate="'required'"
                v-model="training.year"
                v-bind:class="[
                  { 'p-invalid': errors.has('year') },
                  'form-control',
                ]"
                :useGrouping="false"
              />
              <label for="year">Ano</label>
            </span>
            <small v-if="errors.has('year')" class="p-error" role="alert">
              Ano é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-2 p-mt-4" v-if="loggedUer.isEsa || loggedUer.department == 'Administradores'">
            <span class="p-float-label">
              <Dropdown
                name="resposible"
                :options="activeUsers"
                :filter="true"
                :optionLabel="'username'"
                :optionValue="'user_id'"
                v-model="training.responsible_id"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('resposible') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext">Responsável</label>
            </span>
            <small v-if="errors.has('resposible')" class="p-error" role="alert">
              Resposável é obrigatório
            </small>
          </div>

          <div class="p-field p-col-6 p-md-1 p-ml-1 p-mt-4" v-if="training.id">
            <span
              class="p-float-label"
              style="top: -0.4rem; left: -1rem; font-size: 12px"
            >
              <label for="active">Activa</label>
            </span>
            <InputSwitch
              name="active"
              v-model="training.active"
              class="p-mt-1"
            />
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-4">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-model="training.description"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('description') },
                  'form-control',
                ]"
                :name="'description'"
              />
              <label for="description">Descrição</label>
            </span>
            <small
              v-if="errors.has('description')"
              class="p-error"
              role="alert"
            >
              Descrição é obrigatório
            </small>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-4">
            <span class="p-float-label">
              <Textarea
                type="text"
                :autoResize="true"
                rows="5"
                v-validate="'required'"
                v-model="training.objective"
                v-bind:class="[
                  { 'p-invalid': errors.has('objective') },
                  'form-control',
                ]"
                :name="'objective'"
              />
              <label for="objective">Objetivos</label>
            </span>
            <small v-if="errors.has('objective')" class="p-error" role="alert">
              Objetivos é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <MultiSelect
                name="employees"
                :options="activeUsers"
                :filter="true"
                :optionLabel="'username'"
                :optionValue="'user_id'"
                v-model="employeesSelected"
                v-validate="'required'"
                display="chip"
                v-bind:class="[
                  { 'p-invalid': errors.has('employees') },
                  'form-control',
                ]"
              >
              </MultiSelect>
              <label for="inputtext">Selecionar Funcionário</label>
            </span>
            <small v-if="errors.has('employees')" class="p-error" role="alert">
              Funcionário é obrigatório
            </small>
          </div>
        </div>
        <div class="p-grid p-col-12 p-lg-12" v-if="training.employeesCount > 0">
          <div class="p-col-12 p-md-12">
            <h3>Avaliações</h3>
          </div>
          <div
            class="p-field p-col-12 p-md-3"
            v-for="employe in training.employees"
            :key="employe.id"
          >
            <h5
              v-bind:class="[
                { 'text-red': !Number.isInteger(employe.evaluation) },
              ]"
            >
              {{ employe.username }}
            </h5>
            <Rating
              v-model="employe.evaluation"
              @input="createUpdateUserTrainingEvaluation(employe)"
            />
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveTraining"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeForm"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api/";
import trainingService from "../services/training.service";
import employeeService from "../services/employee.service";
export default {
  name: "TrainingActions",
  data() {
    return {
      loading: true,
      trainingList: [],
      activeUsers: [],
      loggedUer: null,
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        creator_username: { value: null, matchMode: FilterMatchMode.CONTAINS },
        responsible_username: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
        active: { value: null, matchMode: FilterMatchMode.EQUALS },
        year: { value: null, matchMode: FilterMatchMode.EQUALS },
        duration: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      showForm: false,
      training: {},
      employeesSelected: null,
      year: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      filtered: false,
    };
  },
  async created() {
    this.loggedUer = this.$store.state.auth.user;
    await this.getActiveUsers();
    await this.getTrainings();
    this.loading = false;
  },
  methods: {
    getActiveUsers() {
      return employeeService
        .getActives()
        .then((response) => (this.activeUsers = response));
    },
    getTrainings() {
      this.loading = true;
      this.filtered = false;
      return trainingService.getTrainings(this.year).then((response) => {
        this.loading = false;
        return (this.trainingList = response);
      });
    },
    getUserSearchLabel(info) {
      return `${info.username}`;
    },
    closeForm() {
      this.showForm = false;
      this.$validator.pause();
      this.$validator.reset();
      this.training = {};
      this.employeesSelected = null;
    },
    edit(training) {
      this.training = training;
      this.employeesSelected = this.training.employees.map((user) => user.id);
      this.getEvaluations();
      this.showForm = true;
    },
    getEvaluations() {
      this.training.employees = this.training.employees.map((v) => ({
        ...v,
        evaluation: this.getTrainingEvaluation(v.id),
      }));
    },
    newTraining() {
      this.employeesSelected = null;
      this.training = { responsible_id: this.loggedUer.id };
      this.showForm = true;
    },
    saveTraining() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.training.id == undefined) {
          return this.saveNewTraining();
        } else {
          return this.updateTraining();
        }
      });
    },
    saveNewTraining() {
      let bodyParms = {
        name: this.training.name,
        year: this.training.year,
        duration: this.training.duration,
        training_entity: this.training.training_entity,
        description: this.training.description,
        objective: this.training.objective,
        cost: this.training.cost,
        responsible_id: this.training.responsible_id,
      };

      if (this.employeesSelected) {
        bodyParms.employees = this.employeesSelected.map((user) => ({
          user_id: user,
        }));
      }
      return trainingService.createTraining(bodyParms).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Ocorreu um erro ao criar a formação",
            life: 3000,
          });
        }
        this.trainingList.push(response);
        this.$toast.add({
          severity: "success",
          summary: "Formação Criada",
          detail: "A formação foi criada com sucesso",
          life: 3000,
        });

        return this.closeForm();
      });
    },
    updateTraining() {
      let bodyParms = {
        name: this.training.name,
        year: this.training.year,
        duration: this.training.duration,
        training_entity: this.training.training_entity,
        description: this.training.description,
        objective: this.training.objective,
        cost: this.training.cost,
        responsible_id: this.training.responsible_id,
      };

      if (this.employeesSelected) {
        bodyParms.employees = this.employeesSelected.map((user) => ({
          user_id: user,
        }));
      }
      return trainingService
        .updateTraining(this.training.id, bodyParms)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao atualizar a formação",
              life: 3000,
            });
          }
          this.$toast.add({
            severity: "success",
            summary: "Formação Atualizada",
            detail: "A formação foi atualizada com sucesso",
            life: 3000,
          });
          const areYou = (trainingAux) => {
            return trainingAux.id == this.training.id;
          };
          let trainigIndex = this.trainingList.findIndex(areYou);
          if (trainigIndex >= 0) {
            this.trainingList.splice(trainigIndex, 1);
            this.trainingList.push(response);
          }
          this.training = response;
          return this.closeForm();
        });
    },
    getTrainingEvaluation(userId) {
      let evaluation = this.training.evaluation.find(
        (training) => training.userId == userId
      );

      if (evaluation) {
        return evaluation.evaluation;
      }
      return null;
    },
    createUpdateUserTrainingEvaluation(employe) {
      if (employe.evaluation == null) {
        employe.evaluation = 0;
      }

      let params = {
        value: employe.evaluation,
      };
      trainingService
        .setTrainingsEvaluation(this.training.id, employe.id, params)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar",
              life: 3000,
            });
          }
        });
    },
    async yearChanged(year) {
      if (year == this.year) {
        return;
      }
      this.loading = true;
      this.year = year;
      await this.getTrainings();
      return (this.loading = false);
    },
    toEvaluated() {
      this.filtered = true;
      this.trainingList = this.trainingList.filter(
        (training) => training.evaluationCount < training.employeesCount
      );
    },
  },
};
</script>
<style>
.text-red {
  color: red;
}
</style>
